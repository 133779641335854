* {
  margin: 0;
  padding: 0;
  transition: 0.3s;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  /* --primary-theme: #6abe52; */
  --primary-theme: #114639;
  --primary-theme2: #448b79;
}

.contact-form input {
  width: 100%;
  height: 42px;
  margin: 8px 0;
  border-radius: 6px;
  border: none;
  padding: 0 15px;
}

.contact-form textarea {
  width: 100%;
  border-radius: 6px;
  border: none;
  margin: 8px 0;
  padding: 8px 15px;
}

.contact-form button {
  width: 100%;
  border-radius: 8px;
  height: 42px;
  background-color: var(--primary-theme);
  color: white;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid white;
}

.read-more-button {
  width: 150px;
  height: 46px;
  border: 2px solid white;
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.5);
  color: white;
  background-color: var(--primary-theme);
  font-size: 16px;
  font-weight: 600;
  border-radius: 2px;
  cursor: pointer;
}

.read-more-button:hover {
  background-color: black;
  scale: 1.1;
  transition: 0.5s;
}

img {
  border-radius: 6px;
}

.main-header-content {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-header-content img {
  width: 200px;
}

.main-header-content-alignment {
  /* flex-direction: column; */
}

.main-header {
  height: 40px;
}

.menu-items {
  /* width: 10%; */
  margin: 0 26px;
}
.toggle-btn {
  display: none;
}

.main-nav-menu-item {
  font-size: 20px;
  color: white;
}
.main-nav-menu-item:hover {
  color: whitesmoke;
}

.bannerLogo {
  width: 30%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.hamburger-menu {
  display: none;
}

.fbLogo {
  background: #316ff6;
  border-radius: 6px;
  padding: 3px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.instaLogo {
  border-radius: 4px;
  padding: 2px;
  background: linear-gradient(
    45deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
}

.active-menu,
.active-menu-item {
  font-weight: 700 !important;
  color: white;
}

.active-menu:visited,
.active-menu-item:visited,
.main-nav-menu-item:visited {
  color: white;
}

.welcome-text {
  text-align: justify;
  line-height: 20px;
}

.map-box {
  width: 100% !important;
}

.contact-us-section {
  background-image: url("./assets/slider2_1.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.social-icons {
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.product-spec {
  text-align: center;
}

.product-spec .product-image {
  text-align: center;
}

.product-spec .product-image img {
  object-fit: cover;
  width: 90%;
}

.spec-btn {
  padding: 1px 10px;
  margin-top: 5px;
  height: 42px;
  background-color: var(--primary-theme);
  color: whitesmoke;
  font-weight: 600;
  border-radius: 8px;
  border: none;
}

.table-header {
  background-color: darkgray;
}

.product-card:hover {
  scale: 1.02;
  cursor: pointer;
}

.product-card img {
  object-fit: cover;
}

.product-card img:hover {
  border-radius: 8px !important;
  object-fit: cover;
  width: 90% !important;
  height: unset;
}

.section-title {
  color: var(--primary-theme);
  font-weight: 700;
  font-size: 34px !important;
}

.slick-next:before,
.slick-prev:before {
  font-size: 30px;
  color: var(--primary-theme) !important;
}

.footer1 {
  /* background-image: url("./assets/footer_1.jpg"); */
  /* background-image: url("./assets/final_asset/footer-bg.jpg"); */
  /* background-size: 100% 217%; */
  /* background-repeat: no-repeat; */
  /* background-position: center; */
  background-color: var(--primary-theme2);
}

.whatsappLogo {
  position: fixed;
  z-index: 999;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background: lime;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-page-image {
  width: 50%;
  float: right;
  margin: 12px 12px 12px 0;
}

.dotSection {
  margin: 20px;
}

.dot,
.color-dot {
  margin: 0 20px;
  min-height: 6px;
  max-height: 6px;
  height: 6px;
  width: 6px;
  max-width: 6px;
  min-width: 6px;
  border-radius: 50%;
}

.dot {
  background-color: red;
}

.color-dot {
  background-color: red;
}

.cert-card {
  width: 320px;
  min-height: 200px;
}

.cert-card img {
  width: 240px;
  height: auto;
  margin: auto;
}

.cert-card:hover {
  scale: 1.2;
}

.cert-card-footer {
  background-color: whitesmoke;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  border-top: 1px solid lightgray;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.member-card-footer {
  background-color: whitesmoke;
  min-height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  border-top: 1px solid lightgray;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.product-desc-image {
  float: left;
  margin: 0 10px 0 0;
  width: 400px;
}

.slide-product {
  width: 70%;
}

.slider-container {
  margin: 30px 0 0 0;
  padding: 0 0 40px 0;
}

@media only screen and (max-width: 768px) {
  .toggle-btn {
    display: unset;
  }

  .main-header-hide-content {
    display: none !important;
  }

  .bannerLogo {
    width: 100%;
  }

  .main-header-content {
    width: 50%;
    justify-content: start;
  }

  .main-header-content img {
    width: 100px;
  }

  .main-header {
    height: 70px;
    box-shadow: 0 0 12px 1px #000000;
    position: sticky;
    top: 0;
    background-color: white;
    max-width: 100%;
    z-index: 99;
  }

  .hamburger-menu {
    display: flex;
    align-items: center;
  }

  .footer1 {
    background-image: url("./assets/footer_2.jpg");
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .section-title {
    font-size: 28px !important;
  }

  .product-page-image {
    width: 80%;
    float: unset;
    display: flex;
    margin: auto;
  }

  .contact-us-section {
    background-size: auto 100%;
  }

  .product-desc-image {
    float: unset;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .slide-product {
    width: 50%;
  }

  .slider-container {
    margin: 24px 0 0px;
    padding: 0 0 40px;
  }
}

@media only screen and (max-width: 576px) {
  .slide-product {
    width: 95%;
  }
}
